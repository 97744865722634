import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { AgGridReact, CustomCellRendererProps } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { formatCell, getValueAsNumber } from './utils';
import LinkCell from './LinkCell';
import './GridTable.css';
import 'ag-grid-enterprise';
import 'ag-grid-charts-enterprise';
import {
  SideBarDef,
  LicenseManager,
  ChartRef,
  ChartType,
  ColumnState,
  ColDef,
  ValueFormatterParams,
  FilterModel,
} from 'ag-grid-enterprise';
import { LicenseManager as ChartsLicenseManager } from 'ag-grid-charts-enterprise';
import ChangeCell, { ChangeColour } from './ChangeCell';
import { format } from 'date-fns';

const LICENSE_KEY =
  'Using_this_{AG_Charts_and_AG_Grid}_Enterprise_key_{AG-061231}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Unicorn_AI_Limited}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{fern}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{fern}_need_to_be_licensed___{fern}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Charts_and_AG_Grid}_Enterprise_versions_released_before_{5_June_2025}____[v3]_[0102]_MTc0OTA3ODAwMDAwMA==514ab5b01fc576a7daece7a81b204fd3';

LicenseManager.setLicenseKey(LICENSE_KEY);
ChartsLicenseManager.setLicenseKey(LICENSE_KEY);

// eslint-disable-next-line
interface ChangeCellParams extends CustomCellRendererProps {
  positiveChange: ChangeColour;
  negativeChange: ChangeColour;
}

export type TableHeader = {
  field: string;
  headerName: string;
  filter: string;
  symbol?: SymbolType;
  link?: string;
  positiveChange?: string;
  negativeChange?: string;
  isPercentageChangeColumn?: boolean;
  // eslint-disable-next-line
  aggFunc?: string | ((params: any) => any);
  // eslint-disable-next-line
  valueGetter?: (params: any) => any;
  enableRowGroup?: boolean;
};

type ChartState = {
  chartType: ChartType;
  // eslint-disable-next-line
  chartOptions: any;
};

export type TableState = {
  columnState: ColumnState[] | undefined;
  filterState: FilterModel | undefined;
  chartState: ChartState | undefined;
  showChart: boolean;
};

type Props = {
  // eslint-disable-next-line
  results: any[];
  currencyCode: string | undefined;
  tableHeaders: TableHeader[];
  showPagination: boolean;
  showToolbar: boolean;
  enableCharts: boolean;
  gridId: string;
  loading?: boolean;
  // eslint-disable-next-line
  showGrandTotalRow?: boolean;
  tableState?: TableState;
  chartId?: string;
  showChart?: boolean;
  updateColumnState?: (columnState: ColumnState[]) => void;
  updateFilterState?: (filterState: FilterModel) => void;
  updateChartState?: (chartState: string) => void;
};

export enum SymbolType {
  CURRENCY = 'CURRENCY',
  PERCENTAGE = 'PERCENTAGE',
}

export type ChartDataType =
  | 'category'
  | 'excluded'
  | 'series'
  | 'time'
  | undefined;

const DEFAULT_CHART_DATA_TYPE: ChartDataType = 'series';
export const DEFAULT_CHART_TYPE = 'groupedColumn';

export default function GridTable({
  results,
  currencyCode,
  tableHeaders,
  showPagination,
  loading,
  showGrandTotalRow = false,
  showToolbar,
  enableCharts,
  tableState,
  chartId,
  showChart,
  updateColumnState,
  updateFilterState,
  updateChartState,
  gridId,
}: Props): JSX.Element {
  const [colDefs, setColDefs] = useState<ColDef[]>([]);
  const [tableReady, setTableReady] = useState<boolean>(false);

  const gridRef = useRef<AgGridReact>(null);
  let currentChartRef: ChartRef | undefined;

  const setHeaders = useCallback(() => {
    const newColDefs = tableHeaders.map((header) => {
      if (header.link != null) {
        return {
          field: header.field,
          filter: header.filter,
          headerName: header.headerName,
          cellRenderer: LinkCell,
          aggFunc: null,
          cellRendererParams: {
            link: header.link,
          },
        };
      }

      if (header.enableRowGroup === true) {
        return {
          field: header.field,
          filter: header.filter,
          headerName: header.headerName,
          aggFunc: () => '-',
          enableRowGroup: header.enableRowGroup,
        };
      }

      if (header.isPercentageChangeColumn === true) {
        return {
          field: header.field,
          filter: header.filter,
          headerName: header.headerName,
          chartDataType: DEFAULT_CHART_DATA_TYPE,
          cellRenderer: (params: ChangeCellParams) => {
            const isGroupRow = params.node.group ?? false;
            const value = getValueAsNumber(
              params.value,
              header.field,
              isGroupRow,
            );
            return ChangeCell(
              value,
              params.positiveChange,
              params.negativeChange,
            );
          },
          aggFunc: header.aggFunc,
          valueGetter: header.valueGetter,
          cellRendererParams: {
            positiveChange: header.positiveChange,
            negativeChange: header.negativeChange,
          },
        };
      }

      if (header.field === 'date') {
        return {
          field: header.field,
          filter: header.filter,
          headerName: header.headerName,
          chartDataType: 'time' as ChartDataType,
          comparator: (valueA: number, valueB: number) => valueA - valueB,
          valueFormatter: (params: ValueFormatterParams) => {
            const isGroupRow = params.node?.group ?? false;
            const value = getValueAsNumber(
              params.value,
              header.field,
              isGroupRow,
            );

            if (params.value == null) {
              return '-';
            }

            const date = new Date(value);
            return format(date, 'dd-MM-yyyy');
          },
        };
      }

      return {
        field: header.field,
        filter: header.filter,
        headerName: header.headerName,
        chartDataType: DEFAULT_CHART_DATA_TYPE,
        valueGetter: header.valueGetter,
        aggFunc: header.aggFunc,
        comparator: (valueA: number, valueB: number) => valueA - valueB,
        valueFormatter: (params: ValueFormatterParams) => {
          const isGroupRow = params.node?.group ?? false;
          const value = getValueAsNumber(
            params.value,
            header.field,
            isGroupRow,
          );
          return formatCell(value, header.symbol, currencyCode);
        },
      };
    });
    setColDefs(newColDefs);
    setTableReady(true);
  }, [tableHeaders]);

  useEffect(() => {
    setHeaders();
  }, [tableHeaders]);

  const showLoading = useCallback(() => {
    if (gridRef.current?.api != null) {
      gridRef.current.api.showLoadingOverlay();
    }
  }, []);

  useEffect(() => {
    if (loading) {
      showLoading();
    }
  }, [loading]);

  const sideBar = useMemo<SideBarDef>(() => {
    if (!showToolbar) {
      return {};
    }
    return {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressValues: true,
            suppressPivots: true,
            suppressPivotMode: true,
            suppressColumnExpandAll: true,
          },
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
      defaultToolPanel: 'null',
    };
  }, []);

  const popupParent = useMemo<HTMLElement | null>(() => {
    return document.body;
  }, []);

  function storeColumnState(): void {
    if (gridRef.current?.api != null && updateColumnState != null) {
      const columnState = gridRef.current.api.getColumnState();
      updateColumnState(columnState);
    }
  }

  function storeFilterState(): void {
    if (gridRef.current?.api != null && updateFilterState != null) {
      const filterState = gridRef.current.api.getFilterModel();
      updateFilterState(filterState);
    }
  }

  useEffect(() => {
    if (tableReady) {
      if (tableState?.columnState != null && gridRef.current?.api != null) {
        gridRef.current.api.applyColumnState({
          state: tableState.columnState,
          applyOrder: true,
        });
      }

      if (tableState?.filterState != null && gridRef.current?.api != null) {
        gridRef.current.api.setFilterModel(tableState.filterState);
      }

      setTableReady(false);
    }
  }, [tableReady]);

  const createChartContainer = useCallback(
    (chartRef: ChartRef) => {
      if (currentChartRef) {
        currentChartRef.destroyChart();
      }
      const eChart = chartRef.chartElement;
      const eParent = document.querySelector(`#${chartId}`);

      if (!eParent) {
        return;
      }
      eParent.appendChild(eChart);
      currentChartRef = chartRef;
    },
    [currentChartRef],
  );

  function showIntegratedChart(): void {
    if (gridRef.current?.api != null) {
      gridRef.current.api.createRangeChart({
        cellRange: {
          columns: gridRef.current.api.getAllDisplayedColumns(),
        },
        chartType: tableState?.chartState?.chartType ?? DEFAULT_CHART_TYPE,
        // @ts-ignore
        chartContainer: document.querySelector(`#${chartId}`),
      });
    }
  }

  useEffect(() => {
    if (showChart) {
      showIntegratedChart();
    }
  }, [showChart, gridRef.current?.api]);

  return (
    <div className={'ag-theme-quartz h-full w-full'}>
      <AgGridReact
        gridId={gridId}
        key={gridId}
        ref={gridRef}
        rowData={results}
        columnDefs={colDefs}
        pagination={showPagination}
        sideBar={sideBar}
        enableCharts={enableCharts}
        enableRangeSelection={false}
        maintainColumnOrder={true}
        createChartContainer={createChartContainer}
        popupParent={popupParent}
        gridOptions={{
          defaultColDef: {
            cellDataType: false,
          },
          chartMenuItems: ['chartEdit', 'chartDownload'],
          chartToolPanelsDef: {
            panels: ['settings'],
          },
        }}
        grandTotalRow={showGrandTotalRow ? 'bottom' : undefined}
        rowGroupPanelShow={'never'}
        suppressAggFuncInHeader={true}
        groupHideOpenParents={false}
        onColumnResized={storeColumnState}
        onColumnMoved={storeColumnState}
        onColumnVisible={storeColumnState}
        onColumnPinned={storeColumnState}
        onSortChanged={storeColumnState}
        onColumnRowGroupChanged={storeColumnState}
        onFilterChanged={storeFilterState}
        onChartOptionsChanged={(event) => {
          const chartType = event.chartType;

          const chartState = JSON.stringify({
            chartType,
          });

          updateChartState && updateChartState(chartState);
        }}
      />
    </div>
  );
}
