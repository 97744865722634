import GridTable from '../../GridTable/GridTable';
import { Dashboard } from 'codegen/graphql';
import DeleteVisualisationModal from '../DeleteVisualisationModal';
import useVisualisation from './useVisualisation';
import useVisualisationData from './useVisualisationData';
import LargeSpinner from 'ui/Spinner/LargeSpinner';
import VisualisationToolbar from './VisualisationToolbar';
import * as Sentry from '@sentry/react';
import { getTableHeadersByDataSource } from 'pages/Reporting/helpers';

type Props = {
  visualisation: Dashboard['visualisations'][number];
  currencyCode: string | undefined;
  profileId: string;
  deleteVisualisation: (visualisationId: string) => void;
  deletingVisualisation: boolean;
  startDate: Date | null;
  endDate: Date | null;
  index: number;
};

export default function Visualisation({
  visualisation,
  currencyCode,
  profileId,
  deleteVisualisation,
  deletingVisualisation,
  startDate,
  endDate,
  index,
}: Props): JSX.Element {
  const {
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    updateColumnState,
    updateFilterState,
    updateChartState,
    displayVisualisation,
    showChart,
    setShowChart,
  } = useVisualisation(visualisation);

  const { visualisationData, loading, marketplaceDataError } =
    useVisualisationData(
      visualisation.dataSource,
      startDate,
      endDate,
      profileId,
    );

  const chartId = `chart-id-${visualisation.id}`;
  const gridId = `grid-id-${visualisation.id}`;

  function getChartState() {
    try {
      return (
        displayVisualisation.chartState &&
        JSON.parse(displayVisualisation.chartState)
      );
    } catch (e) {
      console.error('Error parsing chart state');
      return displayVisualisation.chartState;
    }
  }

  const columnState =
    displayVisualisation.columnState &&
    JSON.parse(displayVisualisation.columnState);
  const filterState =
    displayVisualisation.filterState &&
    JSON.parse(displayVisualisation.filterState);
  const chartState = getChartState();

  const tableState = {
    columnState,
    filterState,
    showChart,
    chartState,
  };

  const tableHeaders = getTableHeadersByDataSource(
    visualisation.dataSource,
    profileId,
  );

  async function deleteCurrentVisualisation(): Promise<void> {
    await deleteVisualisation(visualisation.id);
    setIsDeleteModalOpen(false);
  }

  if (marketplaceDataError) {
    Sentry.captureMessage(
      `Error fetching data for visualisation: ${visualisation.id}`,
    );
    return (
      <div className="text-sm">
        Something went wrong, please try refreshing the page. If the error
        persists, please get in contact with support
      </div>
    );
  }

  return (
    <div key={visualisation.id}>
      <div className="mb-12">
        <VisualisationToolbar
          visualisation={displayVisualisation}
          setIsDeleteModalOpen={setIsDeleteModalOpen}
          showChart={showChart}
          setShowChart={setShowChart}
          index={index}
        />
        <div className={`${showChart && 'hidden'} h-[31rem] mb-6`}>
          {loading && (
            <div className="flex justify-center mt-20">
              <LargeSpinner />
            </div>
          )}
          {!loading && visualisationData.length === 0 && (
            <div className="flex justify-center mt-20">
              <div className="text-neutral-60">
                No results found for this data source
              </div>
            </div>
          )}
          {!loading && visualisationData.length > 0 && (
            <GridTable
              key={visualisation.id}
              results={visualisationData}
              tableHeaders={tableHeaders}
              showPagination={false}
              currencyCode={currencyCode}
              loading={loading}
              showToolbar={true}
              enableCharts={true}
              showGrandTotalRow={true}
              tableState={tableState}
              chartId={chartId}
              gridId={gridId}
              showChart={showChart}
              updateColumnState={updateColumnState}
              updateFilterState={updateFilterState}
              updateChartState={updateChartState}
            />
          )}
        </div>
        {showChart && (
          <div id={chartId} className="ag-theme-quartz h-[41rem]"></div>
        )}
      </div>
      {isDeleteModalOpen && (
        <DeleteVisualisationModal
          open={isDeleteModalOpen}
          setOpen={setIsDeleteModalOpen}
          deletingVisualisation={deletingVisualisation}
          deleteVisualisation={() => deleteCurrentVisualisation()}
        />
      )}
    </div>
  );
}
