import { isEqual } from 'lodash';
import { useAppSelector } from 'state/hooks';

export default function useDashboardSelector() {
  return {
    unsavedChangesModalOpen: useAppSelector(
      (state) => state.dashboard.unsavedChangesModalOpen,
    ),
    visualisationsHaveBeenEdited: useAppSelector((state) => {
      const visualisations = state.dashboard.visualisations;
      const editedVisualisations = state.dashboard.editedVisualisations;

      const allVisualisationsMatch = editedVisualisations.every((vis) => {
        const foundVis = visualisations.find(
          (visualisation) => visualisation.id === vis.id,
        );
        if (!foundVis) {
          return false;
        }
        return isEqual(vis, foundVis);
      });

      return !allVisualisationsMatch;
    }),
  };
}
