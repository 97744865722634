import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dashboard } from 'codegen/graphql';

export type DashboardState = {
  unsavedChangesModalOpen: boolean;
  visualisations: Dashboard['visualisations'];
  editedVisualisations: Dashboard['visualisations'];
};

const initialState: DashboardState = {
  unsavedChangesModalOpen: false,
  visualisations: [],
  editedVisualisations: [],
};

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    storeVisualisations(
      state,
      action: PayloadAction<Dashboard['visualisations']>,
    ) {
      state.visualisations = action.payload;
    },
    resetVisualisations(state) {
      state.visualisations = [];
    },
    storeEditedVisualisation(
      state,
      action: PayloadAction<Dashboard['visualisations'][number]>,
    ) {
      const existingVisualisation = state.editedVisualisations.find(
        (vis) => vis.id === action.payload.id,
      );
      if (!existingVisualisation) {
        state.editedVisualisations.push(action.payload);
        return;
      }
      state.editedVisualisations = state.editedVisualisations.map((vis) =>
        vis.id === action.payload.id ? action.payload : vis,
      );
    },
    resetEditedVisualisations(state) {
      state.editedVisualisations = [];
    },
    updateUnsavedChangesModalOpen(state, action: PayloadAction<boolean>) {
      state.unsavedChangesModalOpen = action.payload;
    },
  },
});

export const {
  updateUnsavedChangesModalOpen,
  storeVisualisations,
  resetEditedVisualisations,
  storeEditedVisualisation,
  resetVisualisations,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
