import { DashboardVisualisationDataSource } from 'codegen/graphql';
import { useRef, useState } from 'react';
import PrimaryButton from 'ui/components/Buttons/PrimaryButton';
import SecondaryButton from 'ui/components/Buttons/SecondaryButton';
import Chevrons from 'ui/components/Dropdowns/Chevrons';
import useClickOutside from 'ui/hooks/useClickOutside';
import Spinner from 'ui/Spinner/Spinner';

const MAX_VISUALISATIONS = 10;

export const visualisationDataSourceLabel = {
  [DashboardVisualisationDataSource.MarketplaceDailyPerformance]: 'Marketplace',
  [DashboardVisualisationDataSource.CampaignsAggregatedPerformance]:
    'Campaigns',
};

type Option = {
  label: string;
  value: DashboardVisualisationDataSource;
};

const dataSourceOptions: Option[] = [
  {
    label:
      visualisationDataSourceLabel[
        DashboardVisualisationDataSource.MarketplaceDailyPerformance
      ],
    value: DashboardVisualisationDataSource.MarketplaceDailyPerformance,
  },
  {
    label:
      visualisationDataSourceLabel[
        DashboardVisualisationDataSource.CampaignsAggregatedPerformance
      ],
    value: DashboardVisualisationDataSource.CampaignsAggregatedPerformance,
  },
];

type Props = {
  addDashboardLoading: boolean;
  numberOfVisualisations: number;
  addVisualisation: (dataSource: DashboardVisualisationDataSource) => void;
};

export default function AddVisualisationDropdown({
  addDashboardLoading,
  numberOfVisualisations,
  addVisualisation,
}: Props): JSX.Element {
  const [open, setOpen] = useState<boolean>(false);

  const ref = useRef(null);
  const dropdownRef = useRef(null);

  useClickOutside(setOpen, ref, dropdownRef);

  const isAddButtonDisabled =
    addDashboardLoading || numberOfVisualisations >= MAX_VISUALISATIONS;

  function selectDataSource(value: string): void {
    const dataSourceSelection = value as DashboardVisualisationDataSource;
    setOpen(false);
    addVisualisation(dataSourceSelection);
  }

  function renderDropdownMenu(): JSX.Element {
    function renderOptions(): JSX.Element[] {
      return dataSourceOptions.map((option) => {
        const { label, value } = option;

        return (
          <li key={value} data-testid={`data-source-selection-${value}`}>
            <SecondaryButton
              onClick={() => {
                selectDataSource(value);
              }}
            >
              <>{label}</>
            </SecondaryButton>
          </li>
        );
      });
    }
    return (
      <div
        ref={ref}
        className="border-light-border absolute top-[2.25rem] !z-50 mt-2 w-50 rounded-xl border bg-white max-h-[16rem] overflow-y-auto scrollbar-hide"
        style={{ boxShadow: '0rem 0.5rem 2rem 0.125rem rgba(0, 0, 0, 0.08)' }}
      >
        <ul className="data-source-dropdown  space-y-4 p-6 text-sm">
          {renderOptions()}
        </ul>
      </div>
    );
  }

  return (
    <div className="flex justify-center mb-20 relative">
      <div ref={dropdownRef}>
        <PrimaryButton
          onClick={() => {
            setOpen(!open);
          }}
          disabled={isAddButtonDisabled}
          width="w-fit"
        >
          {addDashboardLoading ? (
            <div className="flex justify-center">
              <Spinner colour="stroke-white" />
            </div>
          ) : (
            <div className="flex">
              Add Visualisation
              <span className="ml-2 my-auto">
                <Chevrons
                  open={open}
                  disabled={addDashboardLoading}
                  closedColour="stroke-white"
                />
              </span>
            </div>
          )}
        </PrimaryButton>
      </div>
      {open && renderDropdownMenu()}
    </div>
  );
}
