import { useEffect } from 'react';
import useUser from 'hooks/useUser';
import * as Sentry from '@sentry/react';
import {
  UseDashboardGetDashboardDocument,
  UseDashboardGetDashboardQuery,
  UseDashboardAddDashboardMutation,
  UseDashboardAddDashboardDocument,
  UseVisualisationDeleteVisualisationDocument,
  UseVisualisationDeleteVisualisationMutation,
  UseVisualisationUpdateVisualisationDocument,
  UseVisualisationUpdateVisualisationMutation,
  Dashboard,
  DashboardVisualisationDataSource,
} from 'codegen/graphql';
import { useQuery, useMutation, ApolloError } from '@apollo/client';
import { showToast, ToastStyle } from 'design-system/components/Toast/Toast';
import useDashboardDispatch from 'state/dashboard/useDashboardDispatch';
import { removeEditedVisualisationFromLocalStorage } from '../helpers';
import { visualisationDataSourceLabel } from './AddVisualisationDropdown';

type UseDashboardHook = {
  currencyCode: string | undefined;
  dashboardLoading: boolean;
  addVisualisation: (
    dataSource: DashboardVisualisationDataSource,
  ) => Promise<void>;
  deleteVisualisation: (visualisationId: string) => Promise<void>;
  deletingVisualisation: boolean;
  addDashboardLoading: boolean;
  saveDashboard: (continueNavigation?: () => void) => Promise<void>;
  dashboardError: ApolloError | undefined;
  visualisations: Dashboard['visualisations'];
  updatingVisualisationRunning: boolean;
};

export default function useDashboard(profileId: string): UseDashboardHook {
  const { getProfileData } = useUser();
  const {
    resetEditedVisualisations,
    storeVisualisations,
    resetVisualisations,
  } = useDashboardDispatch();

  const profileData = getProfileData(parseInt(profileId));
  const currencyCode = profileData?.currencyCode;

  const {
    data,
    loading: dashboardLoading,
    error: dashboardError,
    refetch,
  } = useQuery<UseDashboardGetDashboardQuery>(
    UseDashboardGetDashboardDocument,
    {
      variables: { marketplaceId: profileId },
    },
  );

  const [
    mutateFunction,
    { loading: addDashboardLoading, error: addDashboardError },
  ] = useMutation<UseDashboardAddDashboardMutation>(
    UseDashboardAddDashboardDocument,
    { ignoreResults: true },
  );

  const [deleteVisualisationMutation, { loading: deletingVisualisation }] =
    useMutation<UseVisualisationDeleteVisualisationMutation>(
      UseVisualisationDeleteVisualisationDocument,
      { ignoreResults: true },
    );

  const [
    updateVisualisationMutation,
    { loading: updatingVisualisationRunning },
  ] = useMutation<UseVisualisationUpdateVisualisationMutation>(
    UseVisualisationUpdateVisualisationDocument,
    { ignoreResults: true },
  );

  const visualisations = data?.dashboard.visualisations ?? [];

  useEffect(() => {
    localStorage.removeItem('editedVisualisations');
    resetEditedVisualisations();
    resetVisualisations();
  }, []);

  useEffect(() => {
    if (data) {
      storeVisualisations(visualisations);
    }
  }, [data]);

  useEffect(() => {
    if (addDashboardError) {
      showToast(
        'Error adding visualisation, please try again',
        ToastStyle.ERROR,
      );
      Sentry.captureMessage(`Error adding visualisation: ${profileId}`);
    }
  }, [addDashboardError]);

  async function deleteVisualisation(visualisationId: string): Promise<void> {
    removeEditedVisualisationFromLocalStorage(visualisationId);

    await deleteVisualisationMutation({
      variables: {
        visualisationId,
        marketplaceId: profileId,
      },
    });
    await refetch();
    showToast('Visualisation successfully removed', ToastStyle.SUCCESS);
  }

  async function addVisualisation(
    dataSource: DashboardVisualisationDataSource,
  ): Promise<void> {
    const dataSourceLabel = visualisationDataSourceLabel[dataSource];
    await mutateFunction({
      variables: {
        marketplaceId: profileId,
        name: `Untitled Visualisation (${dataSourceLabel})`,
        dataSource,
      },
    });
    showToast('Visualisation added', ToastStyle.SUCCESS);
  }

  async function saveDashboard(continueNavigation?: () => void): Promise<void> {
    const storedEditedVisualisations = localStorage.getItem(
      'editedVisualisations',
    );

    if (storedEditedVisualisations == null) {
      return;
    }

    const editedVisualisations = JSON.parse(storedEditedVisualisations);

    for (const visualisation of editedVisualisations) {
      await updateVisualisationMutation({
        variables: {
          type: visualisation.type,
          columnState: visualisation.columnState ?? '',
          filterState: visualisation.filterState ?? '',
          chartState: visualisation.chartState ?? '',
          name: visualisation.name,
          visualisationId: visualisation.id,
          marketplaceId: profileId,
        },
      });
    }

    resetEditedVisualisations();
    await refetch();
    localStorage.removeItem('editedVisualisations');
    showToast('Dashboard updates saved', ToastStyle.SUCCESS);
    if (continueNavigation) {
      continueNavigation();
    }
  }

  return {
    currencyCode,
    dashboardLoading,
    addVisualisation,
    deleteVisualisation,
    deletingVisualisation,
    addDashboardLoading,
    saveDashboard,
    dashboardError,
    visualisations,
    updatingVisualisationRunning,
  };
}
