import { Dashboard, DashboardVisualisationType } from 'codegen/graphql';
import { useState, useEffect } from 'react';
import { ColumnState, FilterModel } from 'ag-grid-enterprise';
import {
  updateEditedVisualisationInLocalStorage,
  findVisualisationInLocalStorage,
} from '../../helpers';
import useDashboardDispatch from 'state/dashboard/useDashboardDispatch';

type UseVisualisationHook = {
  isDeleteModalOpen: boolean;
  setIsDeleteModalOpen: (isOpen: boolean) => void;
  updateColumnState: (columnState: ColumnState[]) => void;
  updateFilterState: (filterState: FilterModel) => void;
  updateChartState: (chartState: string) => void;
  showChart: boolean;
  setShowChart: (show: boolean) => void;
  displayVisualisation: Dashboard['visualisations'][number];
};

export default function useVisualisation(
  visualisation: Dashboard['visualisations'][number],
): UseVisualisationHook {
  const [showChart, setShowChart] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { storeEditedVisualisation } = useDashboardDispatch();

  const editedVisualisation = findVisualisationInLocalStorage(visualisation.id);
  const displayVisualisation = editedVisualisation ?? visualisation;
  const { type } = visualisation;

  useEffect(() => {
    const isChartShowing = type === DashboardVisualisationType.Chart;
    setShowChart(isChartShowing);
  }, []);

  function updateColumnState(columnState: ColumnState[]): void {
    const editedVisualisation = findVisualisationInLocalStorage(
      visualisation.id,
    );
    const currentVisualisation = editedVisualisation ?? visualisation;
    const copiedVisualisation = { ...currentVisualisation };
    copiedVisualisation.columnState = JSON.stringify(columnState);

    updateEditedVisualisationInLocalStorage(copiedVisualisation);
    storeEditedVisualisation(copiedVisualisation);
  }

  function updateFilterState(filterState: FilterModel): void {
    const editedVisualisation = findVisualisationInLocalStorage(
      visualisation.id,
    );
    const currentVisualisation = editedVisualisation ?? visualisation;
    const copiedVisualisation = { ...currentVisualisation };
    copiedVisualisation.filterState = JSON.stringify(filterState);
    storeEditedVisualisation(copiedVisualisation);
    updateEditedVisualisationInLocalStorage(copiedVisualisation);
  }

  function updateChartState(chartState: string): void {
    const editedVisualisation = findVisualisationInLocalStorage(
      visualisation.id,
    );
    const currentVisualisation = editedVisualisation ?? visualisation;
    const copiedVisualisation = { ...currentVisualisation };
    copiedVisualisation.chartState = chartState;
    storeEditedVisualisation(copiedVisualisation);
    updateEditedVisualisationInLocalStorage(copiedVisualisation);
  }

  return {
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    updateColumnState,
    updateFilterState,
    updateChartState,
    displayVisualisation,
    showChart,
    setShowChart,
  };
}
