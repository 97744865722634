import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useSetupRequests from 'api/hooks/requests/useSetupRequests';
import { ApolloError, useQuery, useMutation } from '@apollo/client';
import {
  UseBrandGetBrandQuery,
  UseBrandGetBrandDocument,
  Brand,
  HandleSellingPartnerApiOAuthRedirectDocument,
  HandleSellingPartnerApiOAuthRedirectMutation,
  HandleSellingPartnerApiOAuthRedirectStatus,
  HandleSellingPartnerApiOAuthRedirectMutationVariables,
} from 'codegen/graphql';
import * as Sentry from '@sentry/react';
import {
  HTTP_STATUS_CONFLICT,
  HTTP_STATUS_NO_CONTENT,
} from 'constants/statusCodes';
import { showToast, ToastStyle } from 'design-system/components/Toast/Toast';

const ERROR_MESSAGE = 'This marketplace has already been connected.';

type UseBrandHook = {
  loading: boolean;
  connectMarketplace: (id: string, onComplete: () => void) => Promise<void>;
  isConnectingMarketplace: boolean;
  error: ApolloError | undefined;
  errorMessage: string;
  brand: Brand | undefined;
  isFirstRequestCompleted: boolean;
  brandId: string | undefined;
  sellingPartnerAuthRedirectRunning: boolean;
};

export default function useBrand(): UseBrandHook {
  const [isConnectingMarketplace, setIsConnectingMarketplace] =
    useState<boolean>(false);
  const [isFirstRequestCompleted, setIsFirstRequestCompleted] =
    useState<boolean>(false);

  const [brand, setBrand] = useState<Brand>();

  const { connectProfiles } = useSetupRequests();
  const { brandId } = useParams();
  const [params, setParams] = useSearchParams();
  const state = params.get('state');
  const oAuthCode = params.get('oauth_code');
  const sellingPartnerId = params.get('selling_partner_id');

  const { data, loading, refetch, error } = useQuery<UseBrandGetBrandQuery>(
    UseBrandGetBrandDocument,
    {
      variables: { brandId },
      onCompleted: () => {
        setIsFirstRequestCompleted(true);
      },
    },
  );

  const [
    mutateFunction,
    {
      data: handleSellingPartnerApiData,
      loading: sellingPartnerAuthRedirectRunning,
      error: handleSellingPartnerApiError,
    },
  ] = useMutation<
    HandleSellingPartnerApiOAuthRedirectMutation,
    HandleSellingPartnerApiOAuthRedirectMutationVariables
  >(HandleSellingPartnerApiOAuthRedirectDocument);

  useEffect(() => {
    async function handleBrandConnect(): Promise<void> {
      if (
        brand &&
        state != null &&
        oAuthCode != null &&
        sellingPartnerId != null
      ) {
        await mutateFunction({
          variables: {
            stateToken: state,
            oAuthCode,
            brandId: sellingPartnerId,
            region: brand.regionCode,
          },
        });
        params.delete('state');
        params.delete('oauth_code');
        params.delete('selling_partner_id');
        setParams(params);
        await refetch();
      }
    }
    handleBrandConnect().catch((err: unknown) => {
      Sentry.captureException(err);
      console.error(err);
    });
  }, [brand]);

  useEffect(() => {
    if (
      handleSellingPartnerApiData?.handleSellingPartnerApiOAuthRedirect ===
      HandleSellingPartnerApiOAuthRedirectStatus.Success
    ) {
      showToast('Marketplace connected', ToastStyle.SUCCESS);
    }

    if (
      handleSellingPartnerApiData?.handleSellingPartnerApiOAuthRedirect ===
        HandleSellingPartnerApiOAuthRedirectStatus.Error ||
      handleSellingPartnerApiError
    ) {
      // check if these errors correspond to the `GraphQLError`s in resolver
      // do something with these errors, like tell the user *why*
      Sentry.captureMessage(
        `SP-API connect brand error, BrandId: ${brandId}, Error: ${handleSellingPartnerApiError?.message}`,
      );
      showToast(
        'Error occurred trying to connect marketplace. Please try again.',
        ToastStyle.ERROR,
      );
    }
  }, [handleSellingPartnerApiData]);

  useEffect(() => {
    if (data?.brand) {
      setBrand(data.brand);
    }
  }, [data]);

  async function connectMarketplace(
    id: string,
    onComplete: () => void,
  ): Promise<void> {
    setIsConnectingMarketplace(true);
    try {
      const response = await connectProfiles([parseInt(id)]);
      const { status } = response;
      if (status === HTTP_STATUS_NO_CONTENT) {
        await refetch();
      } else if (status === HTTP_STATUS_CONFLICT) {
        showToast(ERROR_MESSAGE, ToastStyle.ERROR);
        setIsConnectingMarketplace(false);
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setIsConnectingMarketplace(false);
      onComplete();
    }
  }

  return {
    loading,
    connectMarketplace,
    isConnectingMarketplace,
    error,
    errorMessage: ERROR_MESSAGE,
    brand,
    isFirstRequestCompleted,
    brandId,
    sellingPartnerAuthRedirectRunning,
  };
}
