import {
  Dashboard,
  DashboardVisualisationDataSource,
  MarketplaceDailyPerformance,
} from 'codegen/graphql';
import { ChartData } from 'shared/legacy-types';
import {
  AgGridColumnFilter,
  CAMPAIGNS_TABLE_HEADERS,
  CampaignTableHeader,
  MARKETPLACE_TABLE_HEADERS,
} from './GridTable/tableHeaders';
import { MarketplaceData } from './Dashboard/Visualisation/useVisualisationData';

const MINIMUM_TOTAL_DATE_RANGE = 7;
const FIRST_DAY_OF_WEEK = 1;
const FIRST_CHARACTER = 1;

export const convertToPascalCase = (metric: string): string => {
  const pascal = (metric.match(/[a-zA-Z0-9]+/g) ?? [])
    .map(
      (w: string) => `${w.charAt(0).toUpperCase()}${w.slice(FIRST_CHARACTER)}`,
    )
    .join('');

  return pascal.replace(/([A-Z])/g, ' $1').trim();
};

export const convertToChartFormat = (data: any, currencySymbol: string) => {
  return data.map((data: any) => {
    return {
      date: data.date,
      data: [
        {
          type: 'spend',
          value: parseInt(data.cost),
          axis: 'left',
          unit: currencySymbol,
        },
        {
          type: 'acos',
          value: parseFloat(data.acos) || 0,
          axis: 'right',
          unit: '%',
        },
        {
          type: 'adRevenue',
          value: parseInt(data.adSales),
          axis: 'left',
          unit: currencySymbol,
        },
      ],
    } as ChartData;
  });
};

export const showWeekView = (date: string, totalDateRange: number): string => {
  const isMonday = new Date(date).getDay() === FIRST_DAY_OF_WEEK;
  const formattedXAxisDate =
    date &&
    new Date(date).toLocaleDateString('en-GB', {
      month: 'numeric',
      day: 'numeric',
    });
  if (totalDateRange > MINIMUM_TOTAL_DATE_RANGE) {
    if (formattedXAxisDate) {
      if (isMonday) {
        return formattedXAxisDate;
      }

      return '';
    }
  }
  return formattedXAxisDate;
};

export const findVisualisationInLocalStorage = (
  visualisationId: string,
): Dashboard['visualisations'][number] | undefined => {
  const storedEditedVisualisations = localStorage.getItem(
    'editedVisualisations',
  );
  const editedVisualisations = storedEditedVisualisations
    ? JSON.parse(storedEditedVisualisations)
    : [];
  return editedVisualisations.find(
    (vis: Dashboard['visualisations'][number]) => vis.id === visualisationId,
  );
};

export const findEditedVisualisationsInLocalStorage =
  (): Dashboard['visualisations'] => {
    const storedEditedVisualisations = localStorage.getItem(
      'editedVisualisations',
    );
    return storedEditedVisualisations
      ? JSON.parse(storedEditedVisualisations)
      : [];
  };

export const updateEditedVisualisationInLocalStorage = (
  updatedVisualisation: Dashboard['visualisations'][number],
): void => {
  const foundVisualisation = findVisualisationInLocalStorage(
    updatedVisualisation.id,
  );

  const storedEditedVisualisations = findEditedVisualisationsInLocalStorage();

  if (foundVisualisation) {
    const updatedVisualisations = storedEditedVisualisations.map(
      (vis: Dashboard['visualisations'][number]) => {
        if (vis.id === updatedVisualisation.id) {
          return updatedVisualisation;
        }
        return vis;
      },
    );
    localStorage.setItem(
      'editedVisualisations',
      JSON.stringify(updatedVisualisations),
    );
    return;
  }
  storedEditedVisualisations.push(updatedVisualisation);
  localStorage.setItem(
    'editedVisualisations',
    JSON.stringify(storedEditedVisualisations),
  );
};

export const removeEditedVisualisationFromLocalStorage = (
  visualisationId: string,
): void => {
  const storedEditedVisualisations = findEditedVisualisationsInLocalStorage();
  const updatedVisualisations = storedEditedVisualisations.filter(
    (vis: Dashboard['visualisations'][number]) => vis.id !== visualisationId,
  );
  localStorage.setItem(
    'editedVisualisations',
    JSON.stringify(updatedVisualisations),
  );
};

export const getTableHeadersByDataSource = (
  dataSource: Dashboard['visualisations'][number]['dataSource'],
  profileId: string,
) => {
  switch (dataSource) {
    case DashboardVisualisationDataSource.CampaignsAggregatedPerformance:
      return [
        {
          field: CampaignTableHeader.NAME,
          headerName: 'Name',
          filter: AgGridColumnFilter.TEXT,
          link: `/reporting/profile/${profileId}/campaigns`,
        },
        ...CAMPAIGNS_TABLE_HEADERS,
      ];
    default:
      return MARKETPLACE_TABLE_HEADERS;
  }
};

export const mapMarketplaceDataToTableHeaders = (
  marketplaceData: MarketplaceDailyPerformance[],
  marketplace: string,
): MarketplaceData[] => {
  return marketplaceData.map((item) => {
    return {
      id: item.date,
      cost: item.adSpend,
      adSales: item.adRevenue,
      clicks: item.adClicks,
      impressions: item.adImpressions,
      date: parseInt(item.date),
      marketplace,
      adOrders: item.adOrders,
    };
  });
};
