import { useAppDispatch } from 'state/hooks';
import {
  updateUnsavedChangesModalOpen,
  storeVisualisations,
  resetVisualisations,
  storeEditedVisualisation,
  resetEditedVisualisations,
} from './dashboardSlice';
import { Dashboard } from 'codegen/graphql';

export default function useDashboardDispatch() {
  const dispatch = useAppDispatch();

  return {
    updateUnsavedChangesModalOpen: (isOpen: boolean) =>
      dispatch(updateUnsavedChangesModalOpen(isOpen)),
    storeVisualisations: (visualisations: Dashboard['visualisations']) =>
      dispatch(storeVisualisations(visualisations)),
    resetVisualisations: () => dispatch(resetVisualisations()),
    storeEditedVisualisation: (
      visualisation: Dashboard['visualisations'][number],
    ) => dispatch(storeEditedVisualisation(visualisation)),
    resetEditedVisualisations: () => dispatch(resetEditedVisualisations()),
  };
}
