import MinimalRadioDropdown from 'ui/components/Dropdowns/MinimalRadioDropdown';
import Dates from 'components/DatePicker/Dates';
import UnsavedChanges from './UnsavedChanges';
import { useState } from 'react';
import useDashboardDispatch from 'state/dashboard/useDashboardDispatch';
import { useNavigate } from 'react-router-dom';
import useDashboardSelector from 'state/dashboard/useDashboardSelector';

type Props = {
  brandFilter: string;
  setBrandFilter: (value: string) => void;
  marketplaceFilter: string;
  marketplaceOptions: { label: string; value: string }[];
  brandOptions: { label: string; value: string }[];
  setStartDate: (date: Date) => void;
  setEndDate: (date: Date) => void;
  startDate: Date | null;
  endDate: Date | null;
  setMarketplaceFilter: (value: string) => void;
  saveDashboard: (continueNavigation?: () => void) => Promise<void>;
  dashboardLoading: boolean;
  minimumDate?: Date;
};

export default function ControlBar({
  brandFilter,
  setBrandFilter,
  marketplaceFilter,
  marketplaceOptions,
  brandOptions,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  minimumDate,
  setMarketplaceFilter,
  saveDashboard,
  dashboardLoading,
}: Props): JSX.Element {
  const [unsavedChangesContinueFunction, setUnsavedChangesContinueFunction] =
    useState<() => void>();

  const navigate = useNavigate();
  const {
    updateUnsavedChangesModalOpen,
    resetEditedVisualisations,
    resetVisualisations,
  } = useDashboardDispatch();
  const { visualisationsHaveBeenEdited } = useDashboardSelector();

  function updateProfileIdAndNavigate(id: string): void {
    setMarketplaceFilter(id);
    localStorage.removeItem('editedVisualisations');
    resetEditedVisualisations();
    resetVisualisations();
    navigate(`/reporting/profile/${id}`);
    sessionStorage.setItem('reportingProfileId', id);
    setUnsavedChangesContinueFunction(undefined);
  }

  function updateMarketplace(id: string): void {
    if (visualisationsHaveBeenEdited) {
      const continueFunction = () => {
        updateProfileIdAndNavigate(id);
      };
      setUnsavedChangesContinueFunction(() => continueFunction);
      updateUnsavedChangesModalOpen(true);
      return;
    }

    updateProfileIdAndNavigate(id);
  }

  function updateBrandAndNavigate(brand: string): void {
    setBrandFilter(brand);
    resetEditedVisualisations();
    resetVisualisations();
    localStorage.removeItem('editedVisualisations');
    setUnsavedChangesContinueFunction(undefined);
  }

  function updateBrand(brand: string): void {
    if (visualisationsHaveBeenEdited) {
      const continueFunction = () => {
        updateBrandAndNavigate(brand);
      };
      setUnsavedChangesContinueFunction(() => continueFunction);
      updateUnsavedChangesModalOpen(true);
      return;
    }

    updateBrandAndNavigate(brand);
  }

  return (
    <>
      <div className="flex">
        <div className="flex">
          <MinimalRadioDropdown
            label="Brand"
            options={brandOptions}
            selectedValue={brandFilter}
            onChange={(event) => {
              updateBrand(event.target.value);
            }}
          />
        </div>
        <div className="ml-4 flex">
          <MinimalRadioDropdown
            label="Marketplaces"
            options={marketplaceOptions}
            selectedValue={marketplaceFilter}
            onChange={(event) => {
              updateMarketplace(event.target.value);
            }}
          />
        </div>
        <div className="ml-4">
          <Dates
            startDate={startDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            minimumDate={minimumDate}
          />
        </div>
      </div>
      <UnsavedChanges
        saveDashboard={saveDashboard}
        dashboardLoading={dashboardLoading}
        unsavedChangesContinueFunction={unsavedChangesContinueFunction}
        setUnsavedChangesContinueFunction={setUnsavedChangesContinueFunction}
      />
    </>
  );
}
