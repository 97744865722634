import { useCallback, useEffect, useState } from 'react';
import { CampaignMetrics } from 'shared/legacy-types';
import useReportingRequests from 'api/hooks/requests/useReportingRequests';
import { format } from 'date-fns';
import * as Sentry from '@sentry/react';
import {
  DashboardVisualisationDataSource,
  MarketplaceDailyPerformance,
  UseVisualisationDataGetMarketplaceDailyPerformanceDocument,
  UseVisualisationDataGetMarketplaceDailyPerformanceQuery,
} from 'codegen/graphql';
import { ApolloError, useLazyQuery } from '@apollo/client';
import useUser from 'hooks/useUser';
import { mapMarketplaceDataToTableHeaders } from 'pages/Reporting/helpers';

type useVisualisationDataHook = {
  visualisationData: CampaignMetrics[] | MarketplaceData[];
  loading: boolean;
  marketplaceDataError: ApolloError | undefined;
};

export type MarketplaceData = {
  id: string;
  date: number;
  cost: number;
  adSales: number;
  clicks: number;
  impressions: number;
  marketplace: string;
  adOrders: number;
};

export default function useVisualisationData(
  dataSource: DashboardVisualisationDataSource,
  startDate: Date | null,
  endDate: Date | null,
  profileId: string,
): useVisualisationDataHook {
  const [visualisationData, setVisualisationData] = useState<
    CampaignMetrics[] | MarketplaceData[]
  >([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  const formattedStartDate = startDate
    ? format(startDate, 'yyyy-MM-dd')
    : format(new Date(), 'yyyy-MM-dd');
  const formattedEndDate = endDate
    ? format(endDate, 'yyyy-MM-dd')
    : format(new Date(), 'yyyy-MM-dd');

  const { getCampaignReportingMetrics } = useReportingRequests();
  const { getProfileData } = useUser();
  const profile = getProfileData(parseInt(profileId));

  const [
    getMarketplaceData,
    {
      data: marketplaceData,
      loading: marketplaceDataLoading,
      error: marketplaceDataError,
    },
  ] = useLazyQuery<UseVisualisationDataGetMarketplaceDailyPerformanceQuery>(
    UseVisualisationDataGetMarketplaceDailyPerformanceDocument,
    {
      variables: {
        marketplaceId: profileId,
        dateFrom: formattedStartDate,
        dateTo: formattedEndDate,
      },
    },
  );

  const marketplaceDailyPerformance =
    marketplaceData?.marketplaceDailyPerformance as MarketplaceDailyPerformance[];

  const loading = dataLoading || marketplaceDataLoading;

  const loadCampaignMetrics = useCallback(async () => {
    try {
      setDataLoading(true);
      const response = await getCampaignReportingMetrics(
        profileId,
        formattedStartDate,
        formattedEndDate,
        null,
      );
      setDataLoading(false);
      const campaignData = response.data as CampaignMetrics[];
      const formattedData = campaignData.map((campaign) => {
        return {
          ...campaign,
          id: campaign.campaignId,
        };
      });

      setVisualisationData(formattedData);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [formattedEndDate, formattedStartDate, profileId]);

  useEffect(() => {
    switch (dataSource) {
      case DashboardVisualisationDataSource.CampaignsAggregatedPerformance:
        profileId &&
          formattedStartDate &&
          formattedEndDate &&
          loadCampaignMetrics();
        break;
      case DashboardVisualisationDataSource.MarketplaceDailyPerformance:
        profileId && getMarketplaceData();
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [profileId, formattedStartDate, formattedEndDate]);

  useEffect(() => {
    if (marketplaceDailyPerformance && marketplaceDailyPerformance.length > 0) {
      const mappedMarketplaceData = mapMarketplaceDataToTableHeaders(
        marketplaceDailyPerformance,
        profile?.countryCode ?? '',
      );
      setVisualisationData(mappedMarketplaceData);
    }
  }, [marketplaceData]);

  return { visualisationData, loading, marketplaceDataError };
}
